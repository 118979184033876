import { brands } from '@constants';

export const getT3Dealer = (t3Dealer: string) => {
  if (!t3Dealer) {
    return '';
  }

  const stringParts = t3Dealer.split('_');
  const prefix = stringParts[0];
  const suffix = stringParts[1];

  if (!brands.includes(prefix)) {
    return '';
  }

  // any suffix allowed
  if (suffix.length > 0) {
    return prefix;
  }

  return ''; // Return empty string if format is incorrect
};
